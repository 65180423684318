import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    credentials: {},
    cloaking: (() => {
      const cloak = document.querySelector('#cloaking').innerHTML.trim();
      document.querySelector('#cloaking').remove();
      return cloak;
    })(),
  },
  mutations: {
    setCredentials(state, value) {
      state.credentials = value;
    },
  },
  actions: {
    clickHouseEvent(context, event) {
      const formData = new FormData();
      formData.append('event_id', document.querySelector('#eventId').innerText.trim());
      formData.append('event_type', event);
      fetch('https://web2app.fx2.io/event/store',
        {
          method: 'POST',
          body: formData,
        });
    },
  },
  modules: {},
});
