<template>
  <b-modal v-model="modalState"
           hide-footer>
    <div class="d-block position-relative">
      <h5>
        Email
      </h5>
      <div>
        <div>
          <label for="email" class="mb-2">
            Enter your email to get your access details
          </label>
          <input ref="emailInput"
                 id="email"
                 class="form-control email-field"
                 placeholder="example@gmail.com"
                 type="email"
                 @input="validateEmail"/>
          <div ref="alert" class="invalid-feedback fade hide">
            Enter your email please
          </div>
        </div>
        <p class="my-4">
          By continuing you indicate that you've read and agree to our
          <router-link to="/terms">
            Terms & Conditions
          </router-link>
          and
          <router-link to="/privacy">
            Privacy Policy
          </router-link>
          and give your permission to send you an email with content
        </p>
        <div class="d-flex justify-content-center">
          <button ref="sendBtn"
                  class="btn btn-block site-btn site-btn_primary disabled"
                  type="button"
                  @click="sendEmail">
            Continue
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import * as Processing from 'processing';

export default {
  name: 'EmailModal',
  props: [
    'isModalShow',
  ],
  computed: {
    modalState: {
      get() {
        return this.isModalShow;
      },
      set(value) {
        this.$emit('onstatechange', value);
      },
    },
  },
  methods: {
    validateEmail(event) {
      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (validateEmail(event.target.value?.trim())) {
        this.$refs.emailInput.classList.remove('is-invalid');
        this.$refs.sendBtn.classList.remove('disabled');
        this.$refs.alert.classList.replace('show', 'hide');
      } else {
        this.$refs.emailInput.classList.add('is-invalid');
        this.$refs.sendBtn.classList.add('disabled');
        this.$refs.alert.classList.replace('hide', 'show');
      }
    },
    sendEmail() {
      if (this.$refs.emailInput.value?.trim()) {
        Processing.Processing.createOrder(
          this.$refs.emailInput.value?.trim(),
          document.querySelector('#eventId').innerText.trim().includes('{{')
            ? '23239767'
            : document.querySelector('#eventId').innerText.trim(),
          document.querySelector('#landing_uuid').innerText.trim().includes('{{')
            ? '0d5a2249-a2c4-466f-9c87-af847c048d71'
            : document.querySelector('#landing_uuid').innerText.trim(),
        )
          .then(() => {
            this.$router.push('/plans')
              .catch(() => {
              });
          })
          .catch(() => {
            alert('Error mail sending.');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &.show {
    display: block;
  }

  &-head {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #5B5B5B;
    margin-bottom: 24px;
  }

  &-loading-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    z-index: 101;
  }

  &-pay-button {
    &__delimiter {
      font-size: 17px;
      line-height: 25px;
      color: #ACB2C0;
      text-align: center;
      margin: 0;
      position: relative;
      padding: 32px 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }
    }
  }
}
</style>
